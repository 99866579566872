import React from 'react';
import { Link } from 'gatsby';
import Image from '../../elements/Image';
import { GuideArrow } from '../../elements/Icon';
import { BlueGlow, TealGlow } from '../../Glow';

export default function GuidesIndex({ guidesIndex }) {
  return (
    <>
      <GuidesMasthead masthead={guidesIndex?.masthead} />
      <FeaturedGuides featuredGuides={guidesIndex?.featuredGuides} />
    </>
  );
}

function GuidesMasthead({ masthead }) {
  return (
    <section className="bg-offWhite border-b border-black/10 pb-10 pt-32 md:pb-20 md:pt-[11rem] lg:pb-[6.25rem] relative overflow-hidden">
      <div className="container text-center">
        {/* Content */}
        <div className="max-w-[27.625rem] mx-auto relative z-10">
          <div className="relative z-10">
            <span
              className="text-navy t-subheading uppercase font-semibold opacity-[.65] !font-body t-14 tracking-widest"
              dangerouslySetInnerHTML={{ __html: masthead?.subheading }}
            />
            <h1
              className="t-56 md:mt-5 md:mb-4 my-3 font-medium"
              dangerouslySetInnerHTML={{ __html: masthead?.heading }}
            />
            <p className="t-18 mb-6 md:mb-8" dangerouslySetInnerHTML={{ __html: masthead?.contentCopy }} />
            <div>
              <Link to={masthead?.link?.url} target={masthead?.link?.target} className="btn is-blue">
                <span dangerouslySetInnerHTML={{ __html: masthead?.link?.title }} />
              </Link>
            </div>
          </div>
          <div className="offwhite-grad absolute inset-x-[-20%] inset-y-[-25%] sm:inset-x-[-30%] md:inset-x-[-40%] sm:inset-y-[-40%]" />
        </div>
      </div>
      {/* Glow */}
      <TealGlow
        classes={`absolute bottom-[-35%] md:bottom-[-25%] right-[-35%] md:right-[-30%] xl:right-[-25%] opacity-[.2] w-[560px] md:w-[800px] lg:w-[1000px] h-[560px] md:h-[800px]`}
        stopTwo={'20%'}
        stopThree={'60%'}
      />
      <BlueGlow
        classes={`absolute top-[-35%] left-[-35%] md:left-[-35%] xl:left-[-25%] opacity-[.2] w-[560px] md:w-[800px] lg:w-[1000px] h-[560px] md:h-[800px]`}
        stopTwo={'20%'}
        stopThree={'60%'}
      />
      {/* Background */}
      <div className="absolute left-0 inset-y-0 w-1/2">
        <Image image={masthead?.imageLeft} className="object-cover 3xl:object-contain 3xl:object-left object-right" />
      </div>
      <div className="absolute right-0 inset-y-0 w-1/2">
        <Image image={masthead?.imageRight} className="object-cover 3xl:object-contain 3xl:object-right object-left" />
      </div>
    </section>
  );
}

function FeaturedGuides({ featuredGuides }) {
  return (
    <>
      {featuredGuides?.length > 0 &&
        featuredGuides?.map((group, i) => (
          <section key={i} className="mt-10 mb-12 md:mt-16 md:mb-20 lg:mt-[7.5rem] lg:mb-[10rem]">
            <div className="container">
              {/* Content */}
              <div className="max-w-[33.625rem] mx-auto text-center">
                <span
                  className="text-blue t-subheading uppercase font-semibold !font-body t-14 tracking-widest"
                  dangerouslySetInnerHTML={{ __html: group?.subheading }}
                />
                <h2
                  className="t-52 md:mt-5 md:mb-4 my-3 font-medium"
                  dangerouslySetInnerHTML={{ __html: group?.heading }}
                />
                <p className="t-18 mb-6 md:mb-8" dangerouslySetInnerHTML={{ __html: group?.content }} />
              </div>
              {/* Featured Guides */}
              {group?.selectedGuides?.length > 0 && (
                <div className="w-full grid sm:grid-cols-2 lg:grid-cols-3 gap-y-6 sm:gap-6 md:gap-8 mt-8 md:mt-12 lg:mt-[4.5rem]">
                  {group?.selectedGuides?.map((guide, l) => (
                    <GuideCard key={l} guide={guide} />
                  ))}
                </div>
              )}
            </div>
          </section>
        ))}
    </>
  );
}

export function GuideCard({ guide, noBorder, teal }) {
  return (
    <Link
      to={guide?.uri}
      className={` relative overflow-hidden group aspect-[21/25] bg-white pt-6 md:pt-8 lg:pt-10 flex flex-col ${
        noBorder ? '' : 'guide-shadow rounded-[.75rem]'
      }`}
    >
      <h3
        className="t-32 mb-4 relative z-10 px-6 md:px-8 lg:px-10"
        dangerouslySetInnerHTML={{ __html: guide?.title }}
      />
      <div className="w-full mt-auto  px-4 lg:px-5">
        <Image image={guide?.featuredImage?.node} className="w-full object-contain" />
      </div>
      <div
        className="absolute bottom-6 md:bottom-8 lg:bottom-10 left-6 md:left-8 lg:left-10 z-10 transition-all opacity-0 -translate-x-2 group-hover:opacity-100 group-hover:translate-x-0 duration-200 text-white t-18 font-medium"
        dangerouslySetInnerHTML={{ __html: 'View guide' }}
      />
      <div className="absolute bottom-6 md:bottom-8 lg:bottom-10 right-6 md:right-8 lg:right-10 z-10 transition-translate group-hover:translate-x-2 duration-200">
        <GuideArrow />
      </div>
      <div
        className={`absolute z-[5] inset-x-0 top-[50%] bottom-[-10%] translate-y-[100%] group-hover:translate-y-0 opacity-[.6] bg-gradient-to-t ${
          teal ? 'from-teal' : 'from-blue'
        } to-transparent transition-all duration-300`}
      />
    </Link>
  );
}
